@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Nunito+Sans:wght@400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

html{
  scroll-behavior: smooth;
}
.font-montserrat {
  font-family: 'Montserrat', sans-serif !important;
}
.font-nunito {
  font-family: 'Nunito Sans', sans-serif !important;
}
.font-manrope{
  font-family: 'Manrope', sans-serif;
}
button{
  font-weight: 600;
}