@font-face {
  font-family: 'Webxspark-lg';
  src: url('./assets/fonts/logo.otf');
}
.logo{
  font-family: 'Webxspark-lg', serif;
}
.nav-active {
  color: #eb6228;
}
.ant-btn.ant-btn-primary {
  background-color: #1677ff !important;
}
.selection-active{
  background-color: #002ce3 !important;
  color: white !important;
}